<template>
  <div class="videoPlayer_container">
    <!--video非全屏的容器---->
    <div class="play_block" ref="play_block">
      <!--video非全屏的控制栏-->
      <div
        class="not_fullscreen_controls_bar"
        :class="{
          hide_controls_animation: isPlay,
          show_controls: !isPlay,
        }"
        v-show="!isFullscreen"
      >
        <div
          class="controls_bar_Progress"
          @touchstart.stop="ontouchstart"
          @touchmove.stop="ontouchmove"
        >
          <div
            class="progress_bar_tipPoint"
            :style="`left:${progressBarTipPointPos};`"
          ></div>
          <progress
            class="progress_container"
            @click.stop=""
            ref="progress"
            value="0"
            min="0"
          >
            <span ref="progress_bar"></span>
          </progress>
        </div>
        <div class="controls_bar_bottomGroup">
          <div class="controls_bar_bottom">
            <!-- <i :class="isPlay ? 'el-icon-video-pause' : 'el-icon-video-play'"></i> -->
            <div class="video_button_img" @click="playOrPause">
              <img
                :src="require(`@/assets/icon/${isPlay ? 'pause' : 'play'}.png`)"
              />
            </div>
            <div style="font-size: 2vw; color: #fff; padding: 0 2vw">
              {{ videoCurrentMin }}:{{ videoCurrentSec }}/{{
                videoDurationMin
              }}:{{ videoDurationSec }}
            </div>
          </div>
          <div class="controls_bar_bottom">
            <div class="video_button_img" @click="fullscreenchange">
              <img :src="require(`@/assets/icon/fullscreen.png`)" />
            </div>
          </div>
        </div>
      </div>
      <!--end-->
      <video
        :src="videoURL"
        :controls="false"
        @click.stop="playOrPause"
        @pause="onPause"
        @touchmove.stop=""
        controlslist="nofullscreen"
        ref="videoRef"
        playsinline
        @ended="playEnd"
        @timeupdate="updateProgress"
        @loadedmetadata="loadedmetadataed"
        x-webkit-airplay="allow"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
      ></video>
      <!-- @loadedmetadata="loadedmetadataed"webkit-playsinline="true" /*这个属性是ios 10中设置可以让视频在小窗内播放，即不全屏播放*/
        playsinline="true"  /*IOS微信浏览器支持小窗内播放*/
        x-webkit-airplay="allow" /*使此视频支持ios的AirPlay功能*/
        x5-video-player-type="h5"  /*启用H5播放器,是wechat安卓版特性*/
        x5-video-player-fullscreen="true" /*全屏设置，设置为 true 是防止横屏*/ -->
      <div
        @click.stop="playOrPause"
        v-if="!isPlay && !isFullscreen"
        class="essence_play_cover"
      >
        <div class="essence_play_module" ref="moduleRef"></div>
        <img
          v-if="cover && isShowCover"
          ref="posterRef"
          class="play_posterImg"
          :src="cover"
        />
        <div class="essence_play_icon">
          <img
            class="essence_play_icon"
            :src="require('@/assets/images/challenge/play.png')"
          />
        </div>
      </div>
    </div>

    <!--video全屏容器-->
    <div
      v-show="isFullscreen"
      class="video_fullscreen_container"
      @touchmove.stop=""
    >
      <figure
        ref="fullscreen_container"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          class="video_fullscreen_container_play_block"
          ref="video_container"
        ></div>
        <!--video全屏的控制栏-->
        <div class="fullscreen_controls_bar" v-show="isFullscreen">
          <div
            class="controls_bar_Progress"
            @touchstart.stop="onFullscreenTouchstart"
            @touchmove.stop="onFullscreenTouchmove"
          >
            <div
              class="progress_bar_tipPoint"
              :style="`left:${progressBarTipPointPos};`"
            ></div>
            <progress
              class="progress_container"
              @click.stop=""
              ref="progress_fullscreen"
              value="0"
              min="0"
            >
              <span ref="progress_bar_fullscreen"></span>
            </progress>
          </div>
          <div class="controls_bar_bottomGroup">
            <div class="controls_bar_bottom">
              <div class="video_button_img" @click="playOrPause">
                <img
                  :src="
                    require(`@/assets/icon/${isPlay ? 'pause' : 'play'}.png`)
                  "
                />
              </div>
              <div style="font-size: 2vw; color: #fff; padding: 0 2vw">
                {{ videoCurrentMin }}:{{ videoCurrentSec }}/{{
                  videoDurationMin
                }}:{{ videoDurationSec }}
              </div>
            </div>
            <div class="controls_bar_bottom" @click="fullscreenchange">
              <div class="video_button_img">
                <img :src="require(`@/assets/icon/exitfullscreen.png`)" />
              </div>
            </div>
          </div>
        </div>
        <!--end-->
      </figure>
    </div>
  </div>
</template>
<script>
import tool from "@/common/tool";
export default {
  props: {
    videoURL: {
      type: String,
    },
    cover: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isPlay: false,
      isShowCover: true,
      title: "",
      isFullscreen: false,
      progressBarTipPointPos: 0, //进度条小圆点位置
      videoDurationMin: 0, //视频总时长分钟数
      videoDurationSec: 0, //视频总时长去除分钟剩余秒数
      videoCurrentMin: 0, //视频当前播放时长分钟数
      videoCurrentSec: 0, //视频当前播放时长去除分钟剩余秒数
    };
  },
  mounted() {},
  methods: {
    playOrPause() {
      this.isShowCover = false;
      this.$refs.videoRef.play();
      // this.isFullscreen = !this.isFullscreen;
      if (this.isPlay === false) {
        this.$refs.videoRef.play();
        this.$emit("play");
        this.isPlay = true;
      } else {
        this.$refs.videoRef.pause();
        this.$emit("pause");
        this.isPlay = false;
      }
    },
    onPlay() {
      this.isPlay = true;
    },
    onPause() {
      this.isPlay = false;
    },
    skipAhead(pageX) {
      const rect = this.$refs.progress.getBoundingClientRect();
      const pos = (pageX - rect.left) / this.$refs.progress.offsetWidth;
      this.$refs.videoRef.currentTime = pos * this.$refs.videoRef.duration;
      this.updateProgress();
    },

    ontouchstart(e) {
      this.skipAhead(e.touches[0].pageX);
    },

    ontouchmove(e) {
      this.skipAhead(e.touches[0].pageX);
    },

    onFullscreenSkipAhead(pageX) {
      const rect_fullscreen =
        this.$refs.progress_fullscreen.getBoundingClientRect();
      const pos =
        (pageX - rect_fullscreen.left) /
        this.$refs.progress_fullscreen.offsetWidth;
      // console.log(pageX,pos,this.$refs.videoRef.duration)
      this.$refs.videoRef.currentTime = pos * this.$refs.videoRef.duration;
      this.updateProgress();
    },

    onFullscreenTouchstart(e) {
      this.onFullscreenSkipAhead(e.touches[0].pageX);
    },

    onFullscreenTouchmove(e) {
      this.onFullscreenSkipAhead(e.touches[0].pageX);
    },

    loadedmetadataed(e) {
      this.videoDurationMin = String(
        Math.floor(this.$refs.videoRef.duration / 60)
      ).padStart(2, "0");
      this.videoDurationSec = String(
        Math.floor(this.$refs.videoRef.duration % 60)
      ).padStart(2, "0");
      this.videoCurrentMin = String(
        Math.floor(this.$refs.videoRef.currentTime / 60)
      ).padStart(2, "0");
      this.videoCurrentSec = String(
        Math.floor(this.$refs.videoRef.currentTime % 60)
      ).padStart(2, "0");
      this.$refs.progress.setAttribute("max", this.$refs.videoRef.duration);
      this.$refs.progress_fullscreen.setAttribute(
        "max",
        this.$refs.videoRef.duration
      );
    },

    updateProgress() {
      this.videoCurrentMin = String(
        Math.floor(this.$refs.videoRef.currentTime / 60)
      ).padStart(2, "0");
      this.videoCurrentSec = String(
        Math.floor(this.$refs.videoRef.currentTime % 60)
      ).padStart(2, "0");
      this.$refs.progress.value = this.$refs.videoRef.currentTime;
      this.$refs.progress_fullscreen.value = this.$refs.videoRef.currentTime;
      let progress = `${Math.floor(
        (this.$refs.videoRef.currentTime * 100) / this.$refs.videoRef.duration
      )}%`;
      this.$refs.progress_bar.style.width = progress;
      this.$refs.progress_bar_fullscreen.style.width = progress;
      this.progressBarTipPointPos = progress;
    },

    playEnd() {
      this.$emit("playEnd");
    },

    fullscreenchange() {
      let video = this.$refs.videoRef;
      video.webkitEnterFullScreen();
      // this.$refs.videoRef.play();
      // this.isPlay = true;
      // if (tool.getSystemInfo() == "iPhone") return;
      // this.isFullscreen = !this.isFullscreen;
      // if (this.isFullscreen) {
      //   this.$refs.fullscreen_container.requestFullscreen(); //进入全屏
      //   this.$refs.video_container.appendChild(this.$refs.videoRef);
      // } else {
      //   document.exitFullscreen(); //退出全屏
      //   this.$refs.play_block.appendChild(this.$refs.videoRef);
      // }
    },
  },
};
</script>
<style>
.videoPlayer_container {
  width: 100%;
  height: 100%;
}
.essence_play_cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.essence_play_module {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.play_posterImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.essence_play_icon {
  width: 20vw;
  height: 20vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.not_fullscreen_controls_bar {
  /* padding: 4vw; */
  height: 10vw;
  width: 100%;
  background: linear-gradient(#fff0, #444);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fullscreen_controls_bar {
  /* padding: 4vw; */
  height: 10vw;
  width: 100%;
  background: linear-gradient(#fff0, #444);
  /* position: absolute;
  bottom: 0;
  left: 0; */
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.videoPlayer_container .controls_bar_bottomGroup {
  width: 100%;
  flex: 1 0 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videoPlayer_container .controls_bar_bottom {
  /* width: 12.8vw; */
  padding: 0 2vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoPlayer_container .controls_bar_Progress {
  position: relative;
  width: 100%;
  flex: 2vw 0 0;
  /* padding: 0 4px; */
  display: flex;
  align-items: center;
  /* height: 50%; */
}

.videoPlayer_container .progress_bar_tipPoint {
  position: absolute;
  top: 0;
  height: 2vw;
  width: 2vw;
  border-radius: 50%;
  background-color: #fff;
}

.videoPlayer_container .progress_container {
  width: 100%;
  height: 1vw;
}

.videoPlayer_container progress {
  color: orange;
  -webkit-appearance: none;
  appearance: none;
  /*兼容IE10的已完成进度背景*/
  border: none;
  background: #4e4e4e;
  /*这个属性也可当作Chrome的已完成进度背景，只不过被下面的::progress-bar覆盖了*/
}

.videoPlayer_container progress::-webkit-progress-bar {
  background-color: #666666;
  border-radius: 2px;
}

.videoPlayer_container progress::-webkit-progress-value {
  background-color: #ffffff;
  border-radius: 2px;
}

.videoPlayer_container video::-webkit-media-controls-fullscreen-button {
  /* display: none !important; */
}

.show_controls {
  opacity: 1;
}

.hide_controls_animation {
  z-index: 800;
  /* animation-timing-function: linear;
  animation: hide_controls 1s;
  -webkit-animation: hide_controls 1s; */
  /* Safari 和 Chrome */
  /* animation-fill-mode: forwards;  */
}

@keyframes hide_controls {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.video_fullscreen_container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.video_fullscreen_container video {
  width: 100% !important;
  /* height: 100% !important; */
  object-fit: contain;
}
.play_block {
  width: 100%;
  height: 100%;
  position: relative;
}
.video_fullscreen_container_play_block {
  position: relative;
  width: 100vw;
  /* height: 100vw; */
}
.video_button_img {
  width: 5vw;
  height: 5vw;
}
.video_button_img img {
  width: 100%;
  height: 100%;
}
</style>
