<template>
  <div class="votingGroupItem_container global_vote_font">
    <div
      class="votingGroupItem_doctorInfo"
      :class="{ votingGroupItem_doctorInfo_active: index % 2 !== 0 }"
    >
      <div class="votingGroupItem_doctorInfo_num">
        {{ index + 1 }}
      </div>
      <div class="avater_content">
        <img :src="item.profile" class="votingGroupItem_avater" />
        <img
          v-if="item.rank == 1 && item.vote_count !== 0 && !isVote"
          :src="require('@/assets/images/challenge/trophy.png')"
          class="votingGroupItem_trophy"
        />
      </div>
      <div class="votingGroupItem_Info">
        <div class="votingGroupItem_Info_row">
          <p>{{ item.doctor_name }} 医生</p>

          <div
            v-if="item.vote_count !== 0 && isVote && item.rank == 1"
            class="votingGroupItem_count_highest"
          >
            实时票数最高
          </div>
        </div>
        <p>{{ item.doctor_hospital }}</p>
      </div>
      <div class="votingItem_attention">
        <div class="votingItem_attention_block">
          <img
            ref="imgRef"
            :src="getImage()"
            class="votingItem_attention_img"
          />
          <div>{{ item.vote_count }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hidden: "hidden",
    };
  },
  props: {
    item: Object,
    index: Number,
    isVote: Boolean,
    activeType: Boolean,
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.item.vote_count = this.item.vote_count ? this.item.vote_count : 0;
    },
    getImage() {
      let name = this.item.voteForMeCount ? "attention-1" : "attention";
      return require(`@/assets/images/challenge/${name}.png`);
    },
  },
};
</script>

<style >
.votingGroupItem_container {
  width: 100%;
  padding-right: 5.33vw;
  background: #f2f2f3;
  font-size: 3.2vw;
  border-radius: 2vw;
  position: relative;
}
.votingGroupItem_doctorInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.votingGroupItem_doctorInfo_num {
  margin: 0 2.67vw 0 5.33vw;
  font-weight: bold;
  width: 4vw;
  text-align: center;
}
.avater_content {
  position: relative;
  margin: 1.33vw 0 1.33vw 1vw;
}
.votingGroupItem_avater {
  width: 16vw;
  height: 16vw;
  margin-right: 2.67vw;
  border-radius: 50%;
  border: 1px solid #9e004c;
  background: #fff !important;
}

.votingGroupItem_trophy {
  width: 4.67vw;
  height: 4.67vw;
  position: absolute;
  top: 0;
  left: 0;
}
.votingGroupItem_Info {
  height: 16vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.votingGroupItem_Info_row p {
  font-weight: bold;
  font-size: 3.73vw;
}
.votingGroupItem_Info_row {
  display: flex;
  align-items: center;
}
.votingGroupItem_doctorInfo_active {
  background-image: linear-gradient(to right, #eadce4, rgb(242, 242, 243));
}
.votingItem_attention {
  width: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #8d8e8d;
  margin-left: 2.67vw;
}
.votingItem_attention_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.votingItem_attention_img {
  width: 4vw;
  height: 4vw;
}
.votingItem_attention div {
  text-align: end;
}
.votingGroupItem_Info_courname {
  height: 8vw;
  line-height: 4.2vw;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.votingGroupItem_count_highest {
  font-size: 2.67vw;
  background: #9e004c;
  color: #fff;
  padding: 1vw 2vw;
  border-radius: 10vw;
  margin-left: 2vw;
}
</style>