<template>
  <div class="essence_video_content">
    <div class="essence_video_title_block">
      <img
        class="essence_video_title_bg"
        :src="
          !role
            ? require('@/assets/images/challenge/essence.png')
            : require('@/assets/images/challenge/essence-1.png')
        "
      />
      <div class="essence_video_title">
        {{ title }}
      </div>
    </div>
    <!--video非全屏的容器---->
    <div class="essence_play_block" ref="essence_play_block">
      <videoPlayer
        :videoURL="essence_video"
        :cover="require('@/assets/images/challenge/essence_cover.png')"
        ref="videoRef"
        @play="onPlay"
        @pause="onPause"
        @playEnd="playEnd"
      ></videoPlayer>
    </div>
  </div>
</template>

<script>
import tool from "@/common/tool";
import videoPlayer from "@/components/unit/videoPlayer.vue";
export default {
  props: {
    essence_video: {
      type: String,
    },
    class_group: {
      type: Number,
    },
    role: {
      type: Number,
    },
  },

  data() {
    return {
      isPlay: false,
      title: "",
    };
  },
  components: {
    videoPlayer,
  },
  mounted() {},
  watch: {
    class_group(newValue, oldValue) {
      this.title = `第${tool.intToChinese(newValue)}组精华视频合集`;
    },
  },
  methods: {
    startPlay() {
      this.$refs.videoRef.playOrPause();
    },
    onPlay() {
      this.isPlay = true;
    },
    onPause() {
      this.isPlay = false;
    },
    playEnd() {
      this.isPlay = false;
    },
  },
};
</script>

<style >
.essence_video_content {
  background: #fff;
  margin: 3.67vw 5.33vw;
  border-radius: 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.essence_video_title_block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
}
.essence_video_title_bg {
  height: 8vw;
  width: 60vw;
}
.essence_video_title {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: #fff;
  font-size: 4vw;
}
.essence_play_block {
  position: relative;
  width: 80vw;
  height: 81.662vw;
  margin: 4vw 0;
}
</style>